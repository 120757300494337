<template>
  <v-container v-if="vueClassificacao">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          :title="classificacao.descricao"
          :icon="classificacao.icone"
          voltar
          dark
        />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4" v-if="!loading">
          <v-tabs height="40px" v-model="tabs">
            <v-tab class="body-2">
              {{ $tc("global.dados-gerais") }}
            </v-tab>
            <v-tab class="body-2" v-if="vueTipos">
              {{ $tc("global.tipo", 2) }}
            </v-tab>
          </v-tabs>
          <v-card-text class="pa-4" v-if="!atualizando">
            <v-tabs-items v-model="tabs">
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="classificacao.descricao"
                        :label="$tc('global.descricao')"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-model="classificacao.icone"
                        :label="$tc('class.icone')"
                        dense
                        outlined
                        hide-details
                        class="mt-4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      {{ $tc("class.corclass") }}:
                      <v-color-picker
                        v-model="classificacao.cor"
                        hide-mode-switch
                        hide-inputs
                        class="mt-1"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn
                  v-if="vueEditClassificacao"
                  @click="updateClassificacao(classificacao)"
                  fixed
                  right
                  bottom
                  fab
                  dark
                  color="buttons"
                  :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
                >
                  <v-icon>save</v-icon>
                </v-btn>
              </v-tab-item>
              <v-tab-item v-if="vueTipos">
                <ListaEventosTipos :P_classificacao_id="classificacaoId" />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-text v-else>
            <Carregando />
          </v-card-text>
        </v-card>
        <Carregando v-else />
      </v-col>
    </v-row>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import {
  getClassificacao,
  putClassificacoes,
} from "@/services/api/classificacoes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Classificacao",
  components: {
    ListaEventosTipos: () =>
      import(
        "@/views/configuracoes/eventostipos/components/ListaEventosTipos.vue"
      ),
  },
  props: {
    classificacaoId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      classificacao: {},
      tabs: 0,
      colorPicker: {},
      atualizando: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueClassificacao() {
      return this.getAccess("vueClassificacao", "visualizar");
    },
    vueEditClassificacao() {
      return this.getAccess("vueClassificacao", "editar");
    },
    vueTipos() {
      return this.getAccess("vueTipos", "menu");
    },
  },
  watch: {
    classificacaoId() {
      this.getClassificacao();
    },
  },
  methods: {
    getClassificacao() {
      this.loading = true;
      this.$Progress.start();
      getClassificacao(this.classificacaoId)
        .then((response) => {
          this.classificacao = response;
          this.$Progress.finish();
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.classificacao.descricao
          ) {
            this.$store.commit(
              "UPDATE_DYNAMICCRUMB",
              this.classificacao.descricao
            );
          }
        })
        .catch((error) => {
          this.$Progress.fail();
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateClassificacao(classificacao) {
      this.atualizando = true;
      this.$Progress.start();
      putClassificacoes(classificacao.id, classificacao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.atualizadosucesso"));
            this.dialog = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.atualizando = false;
        });
    },
  },
  created() {},
  mounted() {
    if (this.vueClassificacao) {
      this.getClassificacao();
    }
  },
};
</script>

<style lang="scss" scoped></style>
